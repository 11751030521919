import Page from "@/models/Page";
import Node, {
  NodeTypeId,
  QUESTION_CHOICE_TYPES,
  QUESTION_TYPES,
  QUESTION_TYPES_SUPPORTED_PIPING,
  QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS,
} from "@/models/Node";
import OptionSet from "@/models/OptionSet";
import {stripHTML, uuid} from "@/utils/string";
import QuestionType1d, {OptionSetProperty} from "@/models/nodeTypes/QuestionType1d";
import QuestionType2d from "@/models/nodeTypes/QuestionType2d";
import RuleGroup from "@/models/RuleGroup";
import RuleSet, { RuleSetGoToType } from "@/models/RuleSet";
import Element, {ElementType} from "@/models/Element";
import Option from "@/models/Option";
import {HTML_TAG_REGEX, QUESTION_CODE_REGEX, QUESTION_CODE_WITH_HTML_TAGS_REGEX} from "@/constants/questions";
import i18n from "@/plugins/i18n";
import Rule, {RuleChoiceValue, RuleOperator} from "@/models/Rule";
import {CannotDeleteDefaultLocaleTranslationsError, Locale, TranslationData, TranslationFile} from "./translation";
import Quota from "@/models/Quota";
import QuotaGroup from "@/models/QuotaGroup";
import Asset from "./Asset";
import {MapAssets} from "@/composables/api/questionsService";
import SurveyError, {
  RULE_SET_ERROR_PROPERTIES,
  SurveyErrorProperty,
  SurveyErrorType
} from "@/models/errors/SurveyError";
import {LockedItems, Randomization} from "@/models/Randomization";
import {ActiveEditElement, Ref} from "@/typings/global-types";
import Block from "@/models/Block";
import BlockElement from "@/models/BlockElement";
import {cloneDeep} from "@/utils/lodash";
import {DuplicationMaps} from "@/utils/DuplicationMaps";

const { t } = i18n.global

export enum SurveyChangeAction {
  DeleteElement = 'delete-element',
  AddElement = 'add-element',
  MoveElement = 'move-element',
}

export interface SurveyData {
  elements: Array<any>,
  sets: Array<any>,
  quotas: Array<any>,
  locale: string,
  locales: Array<string>,
  requireBlocks: boolean,
  blockRandomization: Randomization,
}

export interface ValidatePipesResult {
  unsupportedPipesNodes: Ref[],
  invalidPositionPipesNodes: Ref[],
}

export interface ValidatePipesResultByNode {
  node: Ref,
  invalidPipedNodes: Ref[],
}

export default class Survey {
  elements: Array<Element> = [];
  sets: Array<OptionSet> = [];
  quotas: Array<Quota | QuotaGroup> = [];
  locale: string = null;
  locales: Array<string> = [];
  errors: SurveyError[];
  blockRandomization: Randomization;

/* TODO - deployment(?)
  //deployment could be taken care of by glue app for now, but it will be needed in UI for other sample sources in future and could be more complex
  // when have multiple different sources w/ different redirects so likely a future concern that will need some thought and updates in QApp in future.
  deployment: Deployment;
  deployment: {
    success: { redirect: "https://s.cint.com/survey/return/%var(externalID)%", message: "you completed" },
    dq: { redirect: "https://s.cint.com/survey/return/%var(externalID)%", message: null },
    oq: { redirect: null, message: "you oq'd" },
  }
  - often has message and/or redirect...though QApp currently only supports a redirect and if not provided, it's a fallback message.
*/

  constructor(
    elements: Array<Element> = [],
    sets: Array<OptionSet> = [],
    quotas: Array<Quota | QuotaGroup> = [],
    locale: string = null,
    locales: Array<string> = null,
    validateSurvey: boolean = true,
    public requireBlocks: boolean = true, // whether all elements must be inside a block
    randomization: Randomization = new Randomization(),
  ) {
    this.errors = [];

    this.addOptionSets(sets ?? []);
    this.addQuotas(quotas ?? []);
    this.addElements(elements ?? []);

    this.locale = locale ? new Locale(locale).ISO15897Code : Locale.default.ISO15897Code;
    this.locales = locales?.map(locale => new Locale(locale).ISO15897Code) || [this.locale];

    this.blockRandomization = randomization ? new Randomization(randomization.items, randomization.methods, randomization.limits) : new Randomization();

    if (validateSurvey) {
      this.validateSurvey(true);
    }
  }

  static fromData(survey: Partial<SurveyData>|null, validateSurvey: boolean = true): Survey {
    return new Survey(
      survey?.elements ?? null,
      survey?.sets ?? null,
      survey?.quotas ?? null,
      survey?.locale ?? null,
      survey?.locales ?? null,
      validateSurvey,
      survey?.requireBlocks ?? true,
      survey?.blockRandomization ?? null
    );
  }

  clone(): Survey {
    return new Survey(
      this.elements,
      this.sets,
      this.quotas,
      this.locale,
      this.locales,
      !!this.errors.length,
      this.requireBlocks,
      this.blockRandomization,
    )
  }

  validateSurvey(validateMaskedOptions: boolean = false, excludeRuleSets: RuleSet[] = [], excludePages: Page[] = [], excludeNodes: Node[] = [], excludeBlocks = []) {
    this.validateNodes(validateMaskedOptions, null, null, excludeNodes);
    this.validatePages(null, excludePages);
    this.validateBlocks(null, excludeBlocks);
    this.validateLogic(false, null, excludeRuleSets);
    this.validateOptionSets();
  }

  /*********************************************************************************************************************/
  /* OPTION SETS */
  /*********************************************************************************************************************/

  validateOptionSets() {
    // remove any option sets not attached to a question
    const linkedOptionSetRefs = new Set<Ref>();

    this.getAllNodes().forEach(node => {
      if (node instanceof QuestionType1d) {
        linkedOptionSetRefs.add(node.set1);

        if (node instanceof QuestionType2d) {
          linkedOptionSetRefs.add(node.set2);
        }
      }
    });

    this.sets.forEach(set => {
      if (!linkedOptionSetRefs.has(set.ref)) {
        this.deleteOptionSet(set);
      }
    })
  }

  addOptionSet(set: OptionSet) {
    this.sets.push(set);
  }

  addOptionSets(sets: Array<OptionSet>) {
    sets.forEach(set => {
      this.sets.push(
        new OptionSet(
          set.ref,
          set.order ?? null,
          set.options ?? null
        )
      )
    })
  }

  deleteOptionSetIfNotInUse(optionSet: OptionSet, fromNode: QuestionType1d, deleteQuestionOptionConfig: boolean = true, optionSetProperty: OptionSetProperty = 'set1') {
    if (deleteQuestionOptionConfig) {
      optionSet.options.forEach((option: Option) => {
        fromNode.deleteQuestionOptionConfiguration(option.ref)
      })
    }

    const quotaGroup = fromNode.getQuestionQuotaGroup(this);
    if (quotaGroup) {
      this.deleteQuestionQuotaGroup(fromNode);
    }

    //TODO: currently it's not possible to have shared option sets in the UI so hardcoding to false for now
    // will need to address impact in masking, etc. when we support this and where the set is removed from one question only
    const optionSetInUse = false; //!this.isOptionSetInUse(optionSet, fromNode)
    if (!optionSetInUse) {
      this.deleteOptionSet(optionSet, fromNode, false, optionSetProperty);
    }

    fromNode[optionSetProperty] = null;
  }

  deleteOptionSet(optionSet: OptionSet, fromNode: QuestionType1d = null, deleteQuestionOptionConfig = true, optionSetProperty: OptionSetProperty = 'set1') {
    const optionSetIndex = this.getOptionSetIndex(optionSet);
    if (optionSetIndex > -1) {
      if (deleteQuestionOptionConfig) {
        this.elements.forEach(element => {
          if (element instanceof Page) {
            element.nodes.forEach(node => {
              if (node instanceof QuestionType1d) {
                optionSet.options.forEach((option: Option) => {
                  node.deleteQuestionOptionConfiguration(option.ref)
                })
              }
            })
          }
        })
      }

      // delete options first to ensure trigger of logic checks, etc.
      optionSet.deleteOptions(this, fromNode, null, optionSetProperty);

      this.sets.splice(optionSetIndex, 1);
    }

    this.resetSurveyErrors(optionSet.ref);
  }

  isOptionSetInUse(optionSet: OptionSet, excludeNode?: QuestionType1d): boolean {
    if (optionSet) {
      for (const element of this.elements) {
        //check each node on page
        if (element instanceof Page) {
          for (const currentNode of element.nodes) {
            if ((excludeNode && currentNode.ref !== excludeNode.ref) && currentNode instanceof QuestionType1d) {
              const isSameSetRef = currentNode instanceof QuestionType1d && currentNode.set1 === optionSet.ref
                || currentNode instanceof QuestionType2d && currentNode.set2 === optionSet.ref;

              if (isSameSetRef) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  getOptionSetByRef(ref: string): OptionSet | null {
    return this.sets.find(el => el.ref === ref) ?? null;
  }

  getOptionSetByOptionRef(optionRef: Ref): OptionSet | null {
    const optionSet = this.sets.find(set => {
      return !!set.options.find(option => option.ref === optionRef);
    })

    return optionSet ?? null;
  }

  getOptionSetIndex(optionSet: OptionSet): number {
    return this.sets.findIndex(el => el.ref === optionSet.ref);
  }

  getAllOptions(): Option[] {
    return this.sets.flatMap(set => set.options);
  }

  getOptionByRef(ref: string): Option | null {
    return this.getAllOptions().find(option => option.ref === ref) ?? null;
  }

  getOptionSetsByNode(node: Node): OptionSet[] {
    const sets = [] as OptionSet[];

    if (node instanceof QuestionType1d) {
      for (let setNum = 1; setNum <= node.constructor['NUM_SETS']; setNum++) {
        const setProperty = 'set' + setNum;
        if (setProperty === 'set2' && !(node instanceof QuestionType2d)) {
          continue;
        }

        sets.push(this.getOptionSetByRef(node[setProperty]));
      }
    }

    return sets;
  }

  /*********************************************************************************************************************/
  /* ELEMENTS */
  /*********************************************************************************************************************/
  addElement(element: Element, index: number = null, validatePositionChange: boolean = false, addToBlock: Block = null): Element {
    const addToElements = addToBlock?.elements ?? this.elements;
    if (index !== null && typeof addToElements[index] !== 'undefined') {
      // if a page, then ensure we're adding the page after any linked logics of the previous page
      if (element instanceof Page) {
        const previousElement = this.getBlockElementByIndex(index, addToBlock);
        if (previousElement instanceof RuleSet) {
          const logics = this.getNextElementsByType(previousElement, ElementType.RuleSet, true, true, true);
          index = index + logics.length;
        }
      }
      addToElements.splice(index, 0, element);
    } else {
      addToElements.push(element);
    }

    // handle randomization updates
    if ((element instanceof Page && addToBlock) || element instanceof Block) {
      const parentElement = element instanceof Page ? addToBlock : this;
      const randomizationProperty = Randomization.getRandomizationProperty(parentElement);
      if (parentElement[randomizationProperty]?.isRandomized()) {
        parentElement[randomizationProperty].updateRandomization(parentElement, element instanceof Page);
      }
    }

    if (validatePositionChange) {
      this.handleElementPositionChange();
    }

    return element;
  }

  addElements(elements: Array<Element>, addToBlock: Block | null = null): Array<Element> {
    const addToElement: Block | Survey = addToBlock ?? this;

    for (const element of elements ?? []) {
      let typedElement;
      let newBlock;
      const baseElementParams = {
        survey: this,
        ref: element.ref ?? null,
        code: element.code ?? null,
        name: element.name ?? null,
        hide: element.hide ?? null,
      };

      switch (element.type) {
        case ElementType.Block:
          typedElement = element as Block;
          newBlock = new Block(baseElementParams, [], typedElement.pageRandomization ?? null);
          this.elements.push(newBlock);
          this.addElements(typedElement.elements ?? [], newBlock);
          break;
        case ElementType.Page:
          typedElement = element as Page;
          addToElement.elements.push(
            new Page(
              baseElementParams,
              typedElement.nodes ?? null,
              typedElement.title ?? null,
              typedElement.actions ?? null,
              typedElement.nodeRandomization ?? null
            )
          )
          break;
        case ElementType.RuleSet:
          typedElement = element as RuleSet;
          addToElement.elements.push(
            new RuleSet(
              baseElementParams,
              typedElement.goToType ?? null,
              typedElement.goTo ?? null,
              typedElement.elseGoToType ?? null,
              typedElement.elseGoTo ?? null,
              new RuleGroup(
                this,
                typedElement.ruleGroup.ref ?? null,
                typedElement.ruleGroup.type ?? null,
                typedElement.ruleGroup.rules ?? null
              )
            )
          )
          break;
        default:
          break;
      }
    }

    if (!addToBlock && this.requireBlocks && addToElement.elements.length) {
      // handle moving elements to blocks, if needed
      this.moveElementsToBlocks()
    }

    return addToElement.elements;
  }

  moveElementsToBlocks() {
    const allBlocks = this.getAllBlocks();
    if (!allBlocks.length) {
      // if no blocks, move all elements into a new block
      const newBlock = new Block({survey: this, code: 1});
      newBlock.elements = this.elements;
      this.elements = [newBlock];
    } else {
      // if blocks exist, move any elements not in a block into a new block - shouldn't happen, but jic
      const elementsNotInBlock = this.elements.filter(element => !(element instanceof Block));
      if (elementsNotInBlock.length) {
        const newBlock = new Block({survey: this, code: allBlocks.length + 1});
        newBlock.elements = elementsNotInBlock;
        allBlocks.push(newBlock);
        this.elements = allBlocks;
      }
    }
  }

  deleteElement(element: Element) {
    switch (true) {
      case element instanceof Block:
        this.deleteBlock(element as Block);
        break;
      case element instanceof Page:
        this.deletePage(element as Page);
        break;
      case element instanceof RuleSet:
        this.deleteRuleSet(element as RuleSet);
        break;
      default:
        break;
    }
  }

  importElement(
    element: Element,
    index: number, // if adding to a block, this is in the index inside the block, else survey.elements index
    name: string = null,
    importFromSurvey: Survey = null,
    importQuestionQuotaGroup: boolean = false,
    importMasking: boolean = false,
    importPipes: boolean = false,
    duplicateRefs: boolean = false,
    addToBlock: Block = null,
  ): Element {
    // note logics are not added when importing elements currently
    return this.duplicateElement(element, index, name, importFromSurvey, importQuestionQuotaGroup, importMasking, importPipes, duplicateRefs, false, addToBlock);
  }

  duplicateElement(
    element: Element,
    index: number, // if adding to a block, this is in the index inside the block, else survey.elements index
    name: string = null,
    duplicateFromSurvey: Survey = null,
    duplicateQuestionQuotaGroup: boolean = false,
    duplicateMasking: boolean = true,
    duplicatePipes: boolean = true,
    duplicateRefs: boolean = true,
    duplicateLinkedLogics: boolean = true,
    addToBlock: Block = null,
    validateLogic: boolean = true,
    validatePositionChange: boolean = true,
  ): Element { // note when duplicating a page + it's linked logics, only the duplicated page will be returned

    //TODO: update this method to duplicate display logic once supported

    const baseElementParams = {
      survey: this,
      ref: duplicateRefs ? uuid() : element.ref,
      code: null,
      name: name ?? element.name,
      hide: element.hide ?? null,
    }
    let newElement;
    addToBlock = addToBlock ?? this.getParentBlockOfElement(element);

    if (element instanceof Block) {
      newElement = this.addElement(new Block(baseElementParams, []), index, false);
      const hasPageRandomization = element.pageRandomization?.isRandomized();
      const currentLockedPageItems: LockedItems = hasPageRandomization ? Randomization.getLockedItems(element) : {};
      const duplicateLockedPageItems: LockedItems = {};

      // create a duplication map to allow updating logic/masking/piping to duplicated parents afterwards
      DuplicationMaps.set(this);

      element.elements.forEach((blockElement, blockElementIndex) => {
        const duplicateElement = this.duplicateElement(
          blockElement,
          blockElementIndex,
          null,
          duplicateFromSurvey,
          duplicateQuestionQuotaGroup,
          duplicateMasking,
          duplicatePipes,
          duplicateRefs,
          false,
          newElement,
          false,
          false,
        );

        if (hasPageRandomization && currentLockedPageItems[blockElement.ref]) {
          duplicateLockedPageItems[duplicateElement.ref] = true;
        }
      })

      // add duplicated page randomization to new block
      if (hasPageRandomization) {
        Randomization.setRandomization(newElement, duplicateLockedPageItems, null, element.pageRandomization?.getRandomizationMethod() ?? null, element.pageRandomization?.getRandomizedDisplayLimit()?.number);
      }

      // update the survey block randomization to include the new block
      const hasBlockRandomization = this.blockRandomization?.isRandomized();
      const currentLockedBlockItems: LockedItems = hasBlockRandomization ? Randomization.getLockedItems(this) : {};
      if (currentLockedBlockItems[element.ref]) {
        // keep the same randomization status (randomized or locked)
        currentLockedBlockItems[newElement.ref] = true;
        this.blockRandomization.updateRandomization(this, false, currentLockedBlockItems);
      }

      // update the duplicated block to replace any logic/masking/piping parent ref's with their new duplicated parents
      this.updateDuplicatedBlockDependencies(newElement);
      DuplicationMaps.clear(this);
    }

    if (element instanceof Page) {
      newElement = new Page(baseElementParams, [], element.title ?? null, element.actions ?? null);
      newElement.addNodes(
        element?.nodes ?? [],
        this,
        true,
        duplicateFromSurvey,
        duplicateQuestionQuotaGroup,
        duplicateMasking,
        duplicatePipes,
        duplicateRefs,
        false
      );

      // set a duplicate map of page refs, when duplicating a block
      const duplicationMaps = DuplicationMaps.get(this);
      duplicationMaps?.duplicatedPageRefsMap.set(element.ref, newElement.ref);

      // update index to add page after any linked logics
      const linkedLogics = element.getLinkedLogics(this) ?? [];
      index = index + linkedLogics.length;

      newElement = this.addElement(newElement, index, validatePositionChange, addToBlock);

      // when duplicating a page, also need to duplicate it's linked logics,
      // skip when duplicating blocks since already duplicating all elements individually, or when importing blocks, as not supported yet
      if (duplicateLinkedLogics) {
        linkedLogics.forEach(logic => {
          this.duplicateElement(
            logic,
            index + 1,
            null,
            duplicateFromSurvey,
            duplicateQuestionQuotaGroup,
            duplicateMasking,
            duplicatePipes,
            duplicateRefs,
            false,
            addToBlock,
            false,
            false
          );

          index++;
        })
      }

      // update the block's page randomization to include the new page
      const hasPageRandomization = addToBlock?.pageRandomization?.isRandomized();
      const currentLockedPageItems: LockedItems = hasPageRandomization ? Randomization.getLockedItems(addToBlock) : {};
      if (currentLockedPageItems[element.ref]) {
        // keep the same randomization status (randomized or locked)
        currentLockedPageItems[newElement.ref] = true;
        addToBlock.pageRandomization.updateRandomization(addToBlock, true, currentLockedPageItems);
      }
    }

    if (element instanceof RuleSet) {
      newElement = new RuleSet(
        baseElementParams,
        (element as RuleSet).goToType ?? null,
        (element as RuleSet).goTo ?? null,
        (element as RuleSet).elseGoToType ?? null,
        (element as RuleSet).elseGoTo ?? null,
        (element as RuleSet).ruleGroup ?? null
      );

      // update to unique rule IDs, if required
      if (duplicateRefs) {
        newElement.ruleGroup.ref = uuid();
        newElement.ruleGroup.rules.forEach(rule => rule.ref = uuid());
      }

      newElement = this.addElement(newElement, index, validatePositionChange, addToBlock);
    }

    if (validatePositionChange) {
      this.handleElementPositionChange();
    }

    return newElement;
  }

  updateDuplicatedBlockDependencies(block: Block) {
    // get duplicate map (original ref -> duplicate ref)
    const duplicationMaps = DuplicationMaps.get(this);

    // update logic
    const updateRule = (rule: Rule) => {
      const duplicatedRuleNode = duplicationMaps.duplicatedNodeRefsMap.get(rule.node);
      if (duplicatedRuleNode !== undefined) {
        rule.node = duplicatedRuleNode;
        const ruleNode = rule.getRuleNode(this);
        if (ruleNode instanceof QuestionType1d) {
          ['set1', 'set2'].forEach(set => {
            const isComplete = rule.isRuleValueSetComplete(set as OptionSetProperty, ruleNode);
            if (isComplete && Array.isArray(rule.value[set])) {
              rule.value[set] = rule.value[set].map(val => duplicationMaps?.duplicatedOptionRefsMap.get(val) ?? val);
            } else if (isComplete) {
              rule.value[set] = duplicationMaps?.duplicatedOptionRefsMap.get(rule.value[set]) ?? rule.value[set];
            }
          });
        }
      }
    };

    const updateRuleGroup = (ruleGroup: RuleGroup) => {
      ruleGroup.rules.forEach(rule => {
        if (rule instanceof RuleGroup) {
          updateRuleGroup(rule);
        } else {
          updateRule(rule);
        }
      });
    };

    const ruleSets = block.getAllRuleSets();
    ruleSets.forEach(ruleSet => {
      // update conditions
      updateRuleGroup(ruleSet.ruleGroup);

      // update goTo's
      ruleSet.goTo = duplicationMaps?.duplicatedPageRefsMap.get(ruleSet.goTo) ?? ruleSet.goTo;
      ruleSet.elseGoTo = duplicationMaps?.duplicatedPageRefsMap.get(ruleSet.elseGoTo) ?? ruleSet.elseGoTo;
    })

    // update masking
    const blockNodes = block.getAllNodes();
    blockNodes.forEach(node => {
      if (node instanceof QuestionType1d) {
        ['set1', 'set2'].forEach(set => {
          node.optionMasking?.[set]?.forEach(mask => {
            mask.parentNode = duplicationMaps?.duplicatedNodeRefsMap.get(mask.parentNode) ?? mask.parentNode;
          });
        });
        Object.values(node.optionConfigurations ?? {}).forEach(option => {
          option.parentRef = duplicationMaps?.duplicatedOptionRefsMap.get(option.parentRef) ?? option.parentRef;
        });
      }
    })

    // update piping
    // first, update node codes and pipes for all nodes
    this.updateNodePipes();

    // next, get the updated node codes for the duplicated block
    const codeChangeMap = new Map();
    duplicationMaps.duplicatedNodeRefsMap.forEach((value, key) => {
      const originalNode = this.getNodeByRef(key);
      const duplicatedNode = this.getNodeByRef(value);

      if (originalNode && duplicatedNode) {
        codeChangeMap.set(originalNode.code, duplicatedNode.code);
      }
    });

    // lastly, reset piping in duplicated block given duplicate parents map
    this.updateNodePipes(blockNodes, codeChangeMap);
  }

  getElementByRef(ref: string): Element | null {
    return this.getAllElements(true).find(el => el.ref === ref) ?? null;
  }

  getEditElementByRef(ref: string): ActiveEditElement | null {
    return this.getElementByRef(ref) ?? this.getNodeByRef(ref) ?? null;
  }

  getEditElementIcon(element: ActiveEditElement) : string {
    return element instanceof Node
      ? element.constructor['NODE_ICON']
      : element.constructor['ELEMENT_ICON'];
  }

  getDeleteElementTypeByRef(ref: string): ActiveEditElement | OptionSet | Option | null {
    return this.getEditElementByRef(ref) ?? this.getOptionSetByRef(ref) ?? this.getOptionByRef(ref) ?? null;
  }

  getBlockElementByIndex(index: number, block: Block = null): BlockElement | null {
    //if !requireBlocks, then this.elements contains only BlockElements
    const elements = block && this.requireBlocks ? block.elements : this.elements;
    return elements[index] as BlockElement ?? null;
  }

  getElementByFlattenedIndex(index: number, includeBlocks: boolean = false): Element | null {
    // gets element using a flattened index (see getElementFlattenedIndex()) in order to compare position in relation to other nested elements
    // i.e. to validate logic, piping, masking, etc.
    return this.getAllElements(includeBlocks)[index] ?? null;
  }

  getElementFlattenedIndex(element: Element, includeBlocks: boolean = false): number {
    // gets an index after flattening elements in order to compare position in relation to other nested elements
    // i.e. to validate logic, piping, masking, etc.
    return this.getAllElements(includeBlocks).findIndex(el => el.ref === element.ref);
  }

  getBlockElementIndex(element: BlockElement, block: Block = null): number {
    // returns index in block.elements, if provided, else within survey.elements
    return this.getBlockElementIndexByRef(element.ref, block);
  }

  getBlockElementIndexByRef(ref: string, block: Block = null): number {
    // returns index in block, if provided, else within survey.elements
    const blockElements = block ? block.elements : this.elements;
    return blockElements.findIndex(el => el.ref === ref);
  }

  getAllBlockElements(): BlockElement[] {
    return this.getAllElements(false).filter(element => element instanceof BlockElement) as BlockElement[];
  }

  getAllElements(includeBlocks: boolean = true): Element[] {
    return this.elements.flatMap(element =>
      includeBlocks && element instanceof Block
        ? [element as Element].concat(element.elements as Element[])
        : element instanceof Block
          ? element.elements : [element]
    );
  }

  getPreviousElementByType(currentElement: Element, types: ElementType | ElementType[], includeBlocks: boolean = false): Element | null {
    const elements = this.getAllElements(includeBlocks);
    const index = this.getElementFlattenedIndex(currentElement, includeBlocks) - 1;
    types = Array.isArray(types) ? types : [types];

    for (let i = index; i >= 0; i--) {
      if (types.includes(elements[i].type)) {
        return elements[i];
      }
    }

    return null;
  }

  getPreviousElementsByType(currentElement: Element, types: ElementType | ElementType[], includeCurrentElement: boolean = false, stopAtNewType: boolean = true, includeBlocks: boolean = false): Element[] | null {
    const elements = this.getAllElements(includeBlocks);
    const index = this.getElementFlattenedIndex(currentElement, includeBlocks) - +!includeCurrentElement;
    const prevElements = [];
    types = Array.isArray(types) ? types : [types];

    for (let i = index; i >= 0; i--) {
      if (types.includes(elements[i].type)) {
        prevElements.push(elements[i]);
      } else if (stopAtNewType) {
        break;
      }
    }

    return prevElements;
  }

  getNextElementByType(currentElement: Element, types: ElementType | ElementType[], includeBlocks: boolean = false): Element | null {
    const elements = this.getAllElements(includeBlocks);
    const index = this.getElementFlattenedIndex(currentElement, includeBlocks) + 1;
    types = Array.isArray(types) ? types : [types];

    for (let i = index; i < elements.length; i++) {
      if (types.includes(elements[i].type)) {
        return elements[i];
      }
    }

    return null;
  }

  getNextElementsByType(currentElement: Element, types: ElementType | ElementType[], includeCurrentElement = false, stopAtNewType = true, includeBlocks: boolean = false): Element[] | null {
    const elements = this.getAllElements(includeBlocks);
    const index = this.getElementFlattenedIndex(currentElement, includeBlocks) + +!includeCurrentElement;
    const nextElements = [];
    types = Array.isArray(types) ? types : [types];

    for (let i = index; i < elements.length; i++) {
      if (types.includes(elements[i].type)) {
        nextElements.push(elements[i]);
      } else if (stopAtNewType) {
        break;
      }
    }

    return nextElements;
  }

  extractElementsFromBlocks(): Element[] {
    const duplicatedElements: Element[] = []
    this.elements.forEach(element => {
      if (element instanceof Block) {
        duplicatedElements.push(...element.elements)
        return element.elements
      }
      duplicatedElements.push(element)
    })

    return duplicatedElements
  }

  moveElement(element: Element, toIndex: number, fromBlock: Block = null, toBlock: Block = null, movePageRuleSets: boolean = false) {
    const fromIndex = this.getBlockElementIndex(element, fromBlock ?? null);
    if (fromIndex > -1) {
      const fromElements = (fromBlock ?? this).elements;
      const toElements = (toBlock ?? this).elements;
      const linkedLogics = movePageRuleSets && element instanceof Page ? element.getLinkedLogics(this) : [];
      const movedTo: Block | Survey = toBlock ?? this;
      const randomizationProperty = Randomization.getRandomizationProperty(movedTo);
      const childRandomizationElements = cloneDeep(Randomization.getAllChildElements(movedTo));
      let randomizedElements = cloneDeep(movedTo[randomizationProperty].getRandomizedItemsAsElements(movedTo));

      const elementsToMove = fromElements.splice(fromIndex, linkedLogics.length + 1);
      toElements.splice(toIndex, 0, ...elementsToMove);

      // handle randomization
      if ((element instanceof Page && fromBlock && toBlock) || element instanceof Block) {
        const toLockedItems = Randomization.getLockedItems(movedTo);

        if (element instanceof Page && fromBlock.pageRandomization?.hasRandomizedItems()) {
          // only with a page, could be moving to/from another page
          fromBlock.pageRandomization.updateRandomization(fromBlock);
        }

        if (movedTo[randomizationProperty]?.isRandomized()) {
          if (element instanceof Block) {
            const firstIndex = childRandomizationElements.findIndex(el => el.ref === randomizedElements[0].ref);
            const lastIndex = childRandomizationElements.findIndex(el => el.ref === randomizedElements[randomizedElements.length - 1].ref);

            // add/remove element if it's moved inside/outside the randomized range
            // including swapping elements position
            if (toIndex < firstIndex || toIndex > lastIndex) {
              randomizedElements = randomizedElements.filter(el => el.ref !== element.ref);
            } else if (!randomizedElements.includes(element)) {
              randomizedElements.push(element);
            }
            movedTo[randomizationProperty].updateRandomization(movedTo, null, toLockedItems, randomizedElements);
          } else {
            movedTo[randomizationProperty].updateRandomization(movedTo);
          }
        }
      }
    }
  }

  handleElementPositionChange(excludeRuleSets: RuleSet[] = [], excludePages: Page[] = [], excludeNodes: Node[] = [], excludeBlocks = []) {
    this.validateSurvey(false, excludeRuleSets, excludePages, excludeNodes, excludeBlocks);
  }

  /*********************************************************************************************************************/
  /* ELEMENTS - BLOCKS  */
  /*********************************************************************************************************************/

  addBlock(block: Block, index: number = null, validateLogic: boolean = true, validatePositionChange: boolean = true): Block {
    return this.addElement(block, index, validatePositionChange) as Block;
  }

  deleteBlock(block: Block) {
    const blockIndex = this.getBlockIndex(block);
    if (blockIndex > -1) {
      // delete block elements first
      block.elements.forEach(element => {
        if (element instanceof Page) {
          this.deletePage(element, false);
        } else if (element instanceof RuleSet) {
          this.deleteRuleSet(element, false);
        }
      })

      this.elements.splice(blockIndex, 1);

      // delete items from randomization
      this.blockRandomization.updateRandomization(this, false);
    }

    this.resetSurveyErrors(null, block.ref);

    this.handleElementPositionChange();
  }

  getBlockByIndex(index: number): Block | null {
    return this.elements[index] instanceof Block ? this.elements[index] as Block : null;
  }

  getBlockIndex(block: Block): number {
    return this.getBlockIndexByRef(block.ref);
  }

  getBlockIndexByRef(ref: string): number {
    return this.elements.findIndex(el => el.ref === ref);
  }

  getAllBlocks(sliceBeforeIndex: number | null = null, sliceAfterIndex: number | null = null): Block[] {
    return this.elements.filter(element =>
      element instanceof Block
      && (sliceBeforeIndex !== null ? this.elements.indexOf(element) < sliceBeforeIndex : true)
      && (sliceAfterIndex !== null ? this.elements.indexOf(element) > sliceAfterIndex : true)
    ) as Block[];
  }

  validateBlocks(specificBlock: Block = null, excludeBlocks: Block[] = []) {
    specificBlock = specificBlock ? this.getElementByRef(specificBlock.ref) as Block : null;
    if (specificBlock) {
      specificBlock.validate(this);
    } else {
      const excludeBlockRefs = excludeBlocks.map(block => block.ref);
      this.getAllBlocks().forEach((block, index) => {
        this.resetSurveyErrors(block.ref);

        block.code = index + 1; // just ensure they're numbered sequentially

        if (!excludeBlockRefs.includes(block.ref)) {
          block.validate(this);
        }
      });
    }
  }

  getParentBlockOfElement(element: ActiveEditElement): Block | null {
    if (element instanceof Block) {
      return null; // blocks can't be nested currently
    }
    return this.getAllBlocks().find(block => block.elements.find(item => {
      if (item instanceof Page && element instanceof Node) {
        return item.nodes.some(node => node.ref === element.ref);
      }
      return item.ref === element.ref;
    })) ?? null;
  }

  /*********************************************************************************************************************/
  /* ELEMENTS - PAGES  */
  /*********************************************************************************************************************/

  addPage(page: Page, addToBlock: Block = null, index: number = null, validatePositionChange: boolean = true): Page {
    return this.addElement(page, index, validatePositionChange, addToBlock) as Page;
  }

  getAllPages(
    sliceBeforeFlattenedIndex: number | null = null,
    sliceAfterFlattenedIndex: number | null = null,
    sliceIndexIncludesBlocks: boolean = false
  ): Page[] {
    const flattenedElements = this.getAllElements(sliceIndexIncludesBlocks);

    return flattenedElements.filter(element => element instanceof Page
      && (sliceBeforeFlattenedIndex !== null ? flattenedElements.indexOf(element) < sliceBeforeFlattenedIndex : true)
      && (sliceAfterFlattenedIndex !== null ? flattenedElements.indexOf(element) > sliceAfterFlattenedIndex : true)
    ) as Page[];
  }

  deletePage(page: Page, validatePositionChange: boolean = true) {
    const block = this.getParentBlockOfElement(page);
    const pageIndex = this.getBlockElementIndex(page, block);

    if (pageIndex > -1) {
      // delete nodes + option sets first
      page.nodes.forEach(node => {
        page.deleteNode(node, this);
      })

      // delete any linked logics
      page.getLinkedLogics(this).forEach(logic => {
        this.deleteRuleSet(logic);
      })

      const elements = block ? block.elements : this.elements;
      elements.splice(pageIndex, 1);
    }

    // delete items from page randomization
    block?.pageRandomization.updateRandomization(block, true);

    this.resetSurveyErrors(null, page.ref);

    if (validatePositionChange) {
      this.handleElementPositionChange();
    }
  }

  validatePages(specificPage: Page = null, excludePages: Page[] = []) {
    specificPage = specificPage ? this.getElementByRef(specificPage.ref) as Page : null;
    if (specificPage) {
      specificPage.validate(this);
    } else {
      const excludePageRefs = excludePages.map(page => page.ref);
      this.getAllPages().forEach((page, index) => {
        this.resetSurveyErrors(page.ref);

        page.code = index + 1; // just ensure they're numbered sequentially

        if (!excludePageRefs.includes(page.ref)) {
          page.validate(this);
        }
      });
    }
  }

  /*********************************************************************************************************************/
  /* ELEMENTS - NODES / QUESTIONS */
  /*********************************************************************************************************************/

  getNodeByRef(nodeRef: string): Node | null {
    return this.getAllNodes().find(node => node.ref === nodeRef) ?? null;
  }

  getNodeIndexByRef(ref: string): number | null {
    const node = this.getNodeByRef(ref);
    return node ? this.getPageOfNode(node).nodes.findIndex(el => el.ref === node.ref) : null;
  }

  getNodeByCode(code: number | string, specificTypes: NodeTypeId[] = null): Node | null {
    return this.getAllNodes(specificTypes).find(node => node.code === code) ?? null;
  }

  getNodeByOption(optionRef: string): Node | null {
    const optionSet = this.getOptionSetByOptionRef(optionRef);
    if (!optionSet) {
      return null
    }

    const node = this.getAllNodes().find(node => {
      return (node instanceof QuestionType1d && node.set1 === optionSet.ref) ||
        (node instanceof QuestionType2d && (node.set1 === optionSet.ref || node.set2 === optionSet.ref))
    })
    return node ?? null;
  }

  getAllNodes(
    specificTypes: NodeTypeId[] = null,
    sliceBeforeFlattenedPageIndex: number | null = null,
    sliceAfterFlattenedPageIndex: number | null = null,
    sliceIndexIncludesBlocks: boolean = false
  ): Node[] {
    const nodes = this.getAllPages(sliceBeforeFlattenedPageIndex, sliceAfterFlattenedPageIndex, sliceIndexIncludesBlocks).flatMap(element => (element as Page).nodes);

    return specificTypes ? nodes.filter(node => specificTypes.includes(node.type)) : nodes;
  }

  getAllNodesMaskedByNode(maskedFromNode: QuestionType1d | QuestionType2d): (QuestionType1d | QuestionType2d)[] {
    const nodes = this.getAllNodes(QUESTION_CHOICE_TYPES) as (QuestionType1d | QuestionType2d)[];

    const parentNodes = nodes.filter(node => {
      return (node?.optionMasking?.set1?.[0]?.parentNode === maskedFromNode.ref) || (node?.optionMasking?.set2?.[0]?.parentNode === maskedFromNode.ref);
    });

    let childNodes: (QuestionType1d | QuestionType2d)[] = [];
    if (parentNodes.length) {
      // recursively find masking on masked children
      for (const parentNode of parentNodes) {
        childNodes = childNodes.concat(this.getAllNodesMaskedByNode(parentNode));
      }
    }

    return [...parentNodes, ...childNodes];
  }

  getAllNodesContainingPipeOfNode(pipedNode: Node): Node[] {
    const nodes = this.getAllNodes();
    return nodes.filter(node => {
      return this.getAllPipesInNode(node).filter(node => node.ref === pipedNode.ref).length;
    });
  }

  getAllPipesInNode(node: Node): Node[] {
    const questionPipes = [...node.text.matchAll(QUESTION_CODE_REGEX)].map(match => match[1]);

    let optionPipes = []
    if (node instanceof QuestionType1d) {
      //TODO: look through translations as well in event different (though rare) - also applies to validation checks
      const set1OptionText = node.getSet1Options(this).map(option => option.label).join(" ");
      optionPipes = [...set1OptionText.matchAll(QUESTION_CODE_REGEX)].map(match => match[1]);

      if (node instanceof QuestionType2d) {
        const set2OptionText = node.getSet2Options(this).map(option => option.label).join(" ");
        optionPipes = optionPipes.concat([...set2OptionText.matchAll(QUESTION_CODE_REGEX)].map(match => match[1]));
      }
    }
    return [...new Set([...questionPipes, ...optionPipes])]
      .map(pipedCode => this.getNodeByCode(parseInt(pipedCode)))
      .filter(node => node !== null);
  }

  hasNodeTypesBeforeFlattenedIndex(flattenedPageIndex: number, nodeTypes: NodeTypeId[] = QUESTION_TYPES): boolean {
    return !!this.getAllNodes(nodeTypes, flattenedPageIndex).length;
  }

  getPageOfNode(node: Node): Page | null {
    return this.getAllPages().filter(page => {
      return page.nodes.find(item => item.ref === node.ref);
    })?.[0] ?? null
  }

  getBlockOfPage(page: Page): Block | null {
    return this.getAllBlocks().find(block => block.getAllPages().find(item => item.ref === page.ref));
  }

  handleNodeTypeChange(nodeUpdated: Node) {
    // the node updated is excluded to avoid immediate errors, it'll be validated on change via builder watcher
    // or when clicking preview/publish
    this.validateNodes(false, nodeUpdated);
    this.validateLogic();
  }

  updateNodeCodes(updateQuotaGroupNames: boolean = true, nodes: Node[] = null): Map<number, number> {
    nodes ??= this.getAllNodes();
    const codeChangeMap = new Map();

    // update codes
    nodes.forEach((question, index) => {
      if (question.code !== index + 1) {
        // original code -> new code
        codeChangeMap.set(question.code, index + 1);
        question.code = index + 1;

        if (updateQuotaGroupNames) {
          this.updateQuestionQuotaGroupName(question);
        }
      }
    })
    return codeChangeMap;
  }

  validateNodes(validateMaskedOptions = false, nodeTypeChange: Node = null, specificNode: Node = null, excludeNodes: Node[] = [], updateNodeCodes: boolean = true) {
    let codeChangeMap = new Map();
    let nodes = this.getAllNodes();
    if (!nodeTypeChange && updateNodeCodes) {
      // update node codes and quota names
      codeChangeMap = this.updateNodeCodes(true, nodes);
    }

    // update pipes, masking and options
    const excludeNodeRefs = excludeNodes.map(node => node.ref);
    specificNode = specificNode ? this.getNodeByRef(specificNode.ref) : null;
    nodes = specificNode
      ? [specificNode]
      : excludeNodes.length
        ? nodes.filter(node => !excludeNodeRefs.includes(node.ref))
        : nodes;

    nodes.forEach(node => {
      node.validate(this, codeChangeMap, true, !nodeTypeChange, true, true, validateMaskedOptions);

      if (node instanceof QuestionType1d) {
        const optionSet1 = node.getSet1(this);
        optionSet1?.validate(this, node, codeChangeMap, true, !nodeTypeChange);
        this.validateQuotas(node);

        if (node instanceof QuestionType2d) {
          const optionSet2 = node.getSet2(this);
          optionSet2?.validate(this, node, codeChangeMap, true, !nodeTypeChange);
        }
      }
    })
  }

  updateNodePipes(nodes: Node[] = null, codeChangeMap: Map<number, number> = null) {
    codeChangeMap ??= this.updateNodeCodes(false);
    nodes ??= this.getAllNodes();

    nodes.forEach(node => {
      // update question text
      this.validatePipes(node, null, codeChangeMap);

      // update option text piping
      if (node instanceof QuestionType1d) {
        const options = node.getSet1Options(this);
        if (node instanceof QuestionType2d) {
          options.concat(node.getSet2Options(this));
        }
        options.forEach(option => {
          this.validatePipes(option, node, codeChangeMap);
        })
      }
    })
  }

  validateQuotas(node: QuestionType1d) {
    const quotaGroup: QuotaGroup = this.getQuestionQuotaGroup(node);
    const nodeQuotaRef = node.quotaGroupRef ?? null;
    const total = quotaGroup?.getTotalAllocated();

    if (total == 0 && nodeQuotaRef) {
      this.deleteQuestionQuotaGroup(node, quotaGroup);
    }
  }

  stripHTMLTags(html: string) {
    return html.replace(QUESTION_CODE_WITH_HTML_TAGS_REGEX, (match: string,
                                                             tag1: string,
                                                             tag2: string,
                                                             pipedCode: string,
                                                             tag3: string,
                                                             tag4: string,
                                                             tag5: string) => {
      const cleanPipedCode = pipedCode.replace(HTML_TAG_REGEX, '');
      if (match !== `{{${cleanPipedCode}}}`) {
        let openingTags = '', closingTags = '';
        const handleTag = (tag: string) => {
          if (tag === tag4 || tag === tag5) { // moves tag1, tag2 and tag 3 (if it's a closing tag) to the right side
            closingTags += tag;
          } else if (tag === tag1 || tag === tag2) { // moves tag5, tag4 and tag 3 (if it's an opening tag) to the left side
            openingTags += tag;
          }
        };
        [tag1, tag2, tag4, tag5].filter(tag => tag).forEach(handleTag);
        tag3?.split(HTML_TAG_REGEX).filter(tag => tag).forEach((tag) => {
          if (tag.includes('/')) {
            closingTags = tag + closingTags; //reversed accumulation
          } else {
            openingTags += tag;
          }
        })

        match = `${openingTags}{{${cleanPipedCode}}}${closingTags}`
      }
      return match;
    })
  }

  validatePipes(element: Node | Option, optionNode: Node = null, codeChangeMap: Map<number, number> = null): ValidatePipesResult {
    const isNode = element instanceof Node;
    const node = isNode ? element : optionNode
    const errorProperty = isNode ? SurveyErrorProperty.NodePiping : SurveyErrorProperty.OptionPiping;
    const nonExistantPipes: string[] = [];
    const unsupportedPipes: string[] = [];
    const invalidPositionPipes: string[] = [];
    let unsupportedPipesNodes: Ref[] = [];
    let invalidPositionPipesNodes: Ref[] = [];
    let invalidPipes: string;

    this.resetSurveyErrors(element.ref, node.ref, [errorProperty]);

    element[isNode ? 'text' : 'label'] = this.stripHTMLTags(element[isNode ? 'text' : 'label']);
    element[isNode ? 'text' : 'label'] = element[isNode ? 'text' : 'label'].replace(QUESTION_CODE_REGEX, (match, pipedCode) => {

      // update pipe to new position
      // TODO: hardcoding "Q" for now, but this will need a decision when we support multi-languages as updating would trigger a survey change:
      //  see: https://maruproduct.atlassian.net/browse/BUILDER-299
      // codeChangeMap = original code -> new code
      const newCode = codeChangeMap?.size ? codeChangeMap.get(parseInt(pipedCode)) : pipedCode;
      if (newCode !== undefined) {
        match = `{{Q${newCode}}}`;
        pipedCode = newCode;
      }

      // check it's valid
      const pipedNode = this.getNodeByCode(parseInt(pipedCode));
      if (!pipedNode) {
        // check it exists, i.e. not deleted or manually manipulated
        // hardcode this one to Q# if int to ensure they don't add a question that then matches as might not be the intended pipe
        // and wouldn't want this to pass on subsequent checks without intention
        pipedCode = /^\d+$/.test(pipedCode) ? '#' : pipedCode;
        match = `{{Q${pipedCode}}}`;
        nonExistantPipes.push('Q' + pipedCode);
      } else if (!QUESTION_TYPES_SUPPORTED_PIPING.includes(pipedNode.type)) {
        // check the question type supports piping - only supported for 1D types for now
        unsupportedPipes.push('Q' + pipedCode);
        unsupportedPipesNodes.push(pipedNode.ref);

      } else if (pipedCode >= node.code || this.getPageOfNode(node) === this.getPageOfNode(pipedNode)) {
        // check it's not before node is asked or on the same page
        invalidPositionPipes.push('Q' + pipedCode);
        invalidPositionPipesNodes.push(pipedNode.ref);
      }

      return match;
    });

    if (nonExistantPipes.length) {
      invalidPipes = [...new Set(nonExistantPipes)].join(', ');
      this.addSurveyError(new SurveyError(SurveyErrorType.NonExistant, errorProperty, element.ref, node.ref, { pipe: invalidPipes }));
    }

    if (unsupportedPipes.length) {
      invalidPipes = [...new Set(unsupportedPipes)].join(', ');
      unsupportedPipesNodes = [...new Set(unsupportedPipesNodes)];
      this.addSurveyError(new SurveyError(SurveyErrorType.Invalid, errorProperty, element.ref, node.ref, {pipe: invalidPipes}));
    }

    if (invalidPositionPipes.length) {
      invalidPipes = [...new Set(invalidPositionPipes)].join(', ');
      invalidPositionPipesNodes = [...new Set(invalidPositionPipesNodes)];
      this.addSurveyError(new SurveyError(SurveyErrorType.Position, errorProperty, element.ref, node.ref, { pipe: invalidPipes }));
    }

    return {
      unsupportedPipesNodes: unsupportedPipesNodes,
      invalidPositionPipesNodes: invalidPositionPipesNodes,
    };
  }

  replaceQuestionPipesWithGenericInsert(question: QuestionType1d | QuestionType2d) {
    question.text = question.text.replace(QUESTION_CODE_REGEX, i18n.global.t('question.questionPipeGenericInsert'));
    const options = [...question.getSet1Options(this), ...(question instanceof QuestionType2d ? question.getSet2Options(this) : [])];
    options.forEach(option => option.label = option.label.replace(QUESTION_CODE_REGEX, i18n.global.t('question.questionPipeGenericInsert')));
  }

  moveNode(node: Node, fromPage: Page, toPage: Page, toIndex: number) {
    const fromIndex = fromPage.getNodeIndex(node);
    if (fromIndex > -1) {
      const fromPageLockedItems = Randomization.getLockedItems(fromPage);
      const toPageLockedItems = Randomization.getLockedItems(toPage);
      fromPage.nodes.splice(fromIndex, 1);
      toPage.nodes.splice(toIndex, 0, node);

      if (fromPage.nodeRandomization?.hasRandomizedItems()) {
        const randomizedElements = fromPage.nodeRandomization.getRandomizedItemsAsElements(fromPage);
        const elementIndex = randomizedElements.findIndex(el => el.ref === node.ref);
        if (elementIndex > -1) {
          randomizedElements.splice(elementIndex, 1);
        }

        if (randomizedElements.length <= 1 && fromPage !== toPage) {
          Randomization.clearRandomization(fromPage);
        } else {
          fromPage.nodeRandomization.updateRandomization(fromPage, null, fromPageLockedItems, randomizedElements);
        }
      }

      if (toPage.nodeRandomization?.hasRandomizedItems()) {
        const randomizedElements = toPage.nodeRandomization.getRandomizedItemsAsElements(toPage).concat([node]);
        toPage.nodeRandomization.updateRandomization(toPage, null, toPageLockedItems, randomizedElements)
      }
    }
  }

  importNode(
    node: Node,
    source: Survey,
    page: Page = null,
    pageIndex: number = null,
    nodeIndex = null,
    importQuotaGroup: boolean = true,
    importMasking: boolean = false,
    importPiping: boolean = false,
    duplicateRefs: boolean = false,
  ): Node {
    const isExistingPage = page instanceof Page && this.getElementByRef(page.ref);
    page = isExistingPage ? page : new Page({survey: this})
    node = page.addNode(node, isExistingPage ? nodeIndex : null, this, true, source, importQuotaGroup, importMasking, importPiping, duplicateRefs);

    if (!isExistingPage) {
      this.addElement(page, pageIndex);
    }

    return node;
  }

  getNodeAssets(node: Node): Array<Asset> {
    const assets = [] as Array<Asset>

    assets.push(...node.assets)
    const sets = this.getOptionSetsByNode(node)
    sets.forEach((set: OptionSet) => assets.push(...set.getAssets()))

    return assets
  }

  replaceAssets(newAssets: Array<MapAssets>, sources: Array<Element> = []): void {
    const elements = sources.length > 0 ? sources : this.elements
    const pages = elements.filter((element: Element) => element instanceof Page)
    const nodes = pages
      .flatMap((page: Page) => page.nodes)
      .filter((node: Node) =>
        this.getNodeAssets(node)
          .some((asset: Asset) =>
            newAssets.some((newAsset: MapAssets) => newAsset.sourceRef === asset.ref)
          )
      );

    const sets: Array<OptionSet> = []
    let assets: Array<Asset> = []
    let oldAsset, newAsset: Asset
    nodes.forEach((node: Node) => {
      assets = []
      newAssets.forEach((mapAsset: MapAssets) => {
        oldAsset = node.assets.find((asset: Asset) => mapAsset.sourceRef === asset.ref)
        if (oldAsset) {
          assets.push({...mapAsset.asset, ...{type: oldAsset.type}})
          node.text = node.text.replace(`src="${oldAsset.url}"`, `src="${mapAsset.asset.url}"`)
        } else {
          sets.push(...this.getOptionSetsByNode(node))
        }
      })
      node.assets = assets
    })
    sets
      .filter((set: OptionSet, index, array) => array.findIndex((s: OptionSet) => s.ref === set.ref) === index) // unique
      .forEach((set: OptionSet) => {
        set.options.forEach((option: Option) => {
          newAsset = newAssets.find((mapAsset: MapAssets) => mapAsset.sourceRef === option.asset?.ref)?.asset
          if (newAsset) {
            option.label = option.label.replace(`src="${option.asset.url}"`, `src="${newAsset.url}"`)
            option.asset = {...newAsset, ...{type: option.asset.type}}
          }
        })
      })
  }

  /*********************************************************************************************************************/
  /* ELEMENTS - RULE SETS */
  /*********************************************************************************************************************/

  addRuleSet(ruleSet: RuleSet, addToBlock: Block = null, index: number = null, validatePositionChange: boolean = true): RuleSet {
    return this.addElement(ruleSet, index, validatePositionChange, addToBlock) as RuleSet;
  }

  deleteRuleSet(ruleSet: RuleSet, validatePositionChange: boolean = true) {
    const block = this.getParentBlockOfElement(ruleSet);
    const index = this.getBlockElementIndex(ruleSet, block);
    if (index > -1) {
      const elements = block ? block.elements : this.elements;
      elements.splice(index, 1);
    }

    this.resetSurveyErrors(null, ruleSet.ref);

    if (validatePositionChange) {
      this.handleElementPositionChange();
    }
  }

  getAllRuleSets(sliceBeforeFlattenedIndex: number | null = null, sliceAfterFlattenedIndex: number | null = null): RuleSet[] {
    const flattenedElements = this.getAllElements(true);
    const ruleSets: RuleSet[] = flattenedElements.filter(element => element instanceof RuleSet) as RuleSet[];

    if (sliceBeforeFlattenedIndex !== null || sliceAfterFlattenedIndex !== null) {
      return ruleSets.filter(ruleSet =>
        (sliceBeforeFlattenedIndex !== null ? flattenedElements.indexOf(ruleSet) < sliceBeforeFlattenedIndex : true)
        && (sliceAfterFlattenedIndex !== null ? flattenedElements.indexOf(ruleSet) > sliceAfterFlattenedIndex : true)
      );
    }

    return ruleSets;
  }

  getAllRuleSetRulesContainingQuestion(question: Node): RuleSet[] {
    const ruleSets = this.getAllRuleSets();
    const ruleSetsContainingQuestion: RuleSet[] = [];

    const doRulesContainQuestion = (rules: (Rule | RuleGroup)[], question: Node): boolean => {
      for (const rule of rules) {
        if (rule instanceof Rule && rule.node === question.ref) {
          return true;
        }
        // check rule groups for grids
        if (rule instanceof RuleGroup && doRulesContainQuestion(rule.rules, question)) {
          return true;
        }
      }
      return false;
    };

    ruleSets.forEach(ruleSet => {
      const rulesContainQuestion = doRulesContainQuestion(ruleSet.ruleGroup.rules, question);
      if (rulesContainQuestion) {
        ruleSetsContainingQuestion.push(ruleSet);
      }
    });

    return ruleSetsContainingQuestion;
  }

  getAllRuleSetGoTosContainingPage(page: Page): RuleSet[] {
    const ruleSets = this.getAllRuleSets();
    const ruleSetsContainingPage: RuleSet[] = [];

    ruleSets.forEach(ruleSet => {
      const goToContainsPage = (ruleSet.goToType === RuleSetGoToType.Skip && ruleSet.goTo === page.ref);
      if (goToContainsPage) {
        ruleSetsContainingPage.push(ruleSet);
      }
    });

    return ruleSetsContainingPage;
  }

  getAllRuleSetElseGoTosContainingPage(page: Page): RuleSet[] {
    const ruleSets = this.getAllRuleSets();
    const ruleSetsContainingPage: RuleSet[] = [];

    ruleSets.forEach(ruleSet => {
      const elseGoToContainsPage = (ruleSet.elseGoToType === RuleSetGoToType.Skip && ruleSet.elseGoTo === page.ref);
      if (elseGoToContainsPage) {
        ruleSetsContainingPage.push(ruleSet);
      }
    });

    return ruleSetsContainingPage;
  }

  getAllRuleSetsContainingOption(option: Option): RuleSet[] {
    const ruleSets = this.getAllRuleSets();
    const ruleSetsContainingOption: Set<RuleSet> = new Set();

    const doRulesContainOption = (rules: (Rule | RuleGroup)[], option: Option): boolean => {
      for (const rule of rules) {
        const value = rule instanceof Rule ? (rule as Rule).value as RuleChoiceValue : null;
        let containsOption = false;
        if (value) {
          containsOption = ['set1', 'set2'].some(set => Array.isArray(value[set]) ? value[set].includes(option.ref) : value[set] === option.ref);
        } else if (rule instanceof RuleGroup) {
          // check if option is in rule group
          containsOption = doRulesContainOption(rule.rules, option);
        }
        if (containsOption) {
          return true;
        }
      }
      return false;
    }

    ruleSets.forEach(ruleSet => {
      if (doRulesContainOption(ruleSet.ruleGroup.rules, option)) {
        ruleSetsContainingOption.add(ruleSet);
      }
    })

    return Array.from(ruleSetsContainingOption);
  }

  getAllRuleSetsContainingOptionSet(optionSet: OptionSet): RuleSet[] {
    const options = optionSet.options;
    return Array.from(new Set(options.flatMap(option => this.getAllRuleSetsContainingOption(option))));
  }

  validateLogicCodes() {
    // just ensure they're numbered sequentially; no dependant logic to update
    const ruleSets = this.getAllRuleSets();
    ruleSets.forEach((ruleSet, index) => {
      ruleSet.code = index + 1;
    })
  }

  validateLogic(rulesOnly = false, specificRuleSet: RuleSet = null, excludeRuleSets: RuleSet[] = [], specificRule: Rule = null) {
    if (specificRule) {
      this.resetSurveyErrors(specificRule.ref)
    } else if (specificRuleSet) {
      this.resetSurveyErrors(null, specificRuleSet.ref);
    } else {
      this.resetSurveyErrors(null, null, RULE_SET_ERROR_PROPERTIES);
    }

    this.validateLogicCodes();

    const excludeRuleSetRefs = excludeRuleSets.map(ruleSet => ruleSet.ref);
    specificRuleSet = specificRuleSet ? this.getElementByRef(specificRuleSet.ref) as RuleSet : null;
    const ruleSets = specificRuleSet
      ? [specificRuleSet]
      : excludeRuleSets.length
        ? this.getAllRuleSets().filter(ruleSet => !excludeRuleSetRefs.includes(ruleSet.ref))
        : this.getAllRuleSets();

    ruleSets.forEach(ruleSet => {
      ruleSet.validate(this, rulesOnly);
    })
  }

  /*********************************************************************************************************************/
  /* QUOTAS */
  /*********************************************************************************************************************/

  addQuota(quota: Quota | QuotaGroup, index: number = null): Quota | QuotaGroup {
    if (index !== null && typeof this.quotas[index] !== 'undefined') {
      this.quotas.splice(index, 0, quota);
    } else {
      this.quotas.push(quota);
    }
    return quota;
  }

  addQuotas(quotas: Array<Quota | QuotaGroup>): Array<Quota | QuotaGroup> {
    quotas.forEach(quota => {
      if (!this.getQuotaByRef(quota.ref)) {
        if (quota instanceof QuotaGroup || this.isObjectQuotaGroup(quota)) {
          this.quotas.push(
            new QuotaGroup(
              this,
              (quota as QuotaGroup).ref ?? null,
              (quota as QuotaGroup).name ?? null,
              (quota as QuotaGroup).quotas ?? null
            )
          )
        } else {
          this.quotas.push(
            new Quota(
              this,
              quota.ref ?? null,
              quota.name ?? null,
              quota.rule ?? null,
              quota.count ?? null,
            )
          )
        }
      }
    })

    return this.quotas;
  }

  addQuestionQuotaGroup(question: QuestionType1d, quotaGroup: QuotaGroup = null, duplicateOptionSetMap: Map<string, string> = null): QuotaGroup {
    const set = question.getSet1(this);
    const duplicate = quotaGroup && !!duplicateOptionSetMap?.size;
    const newQuotaGroup = duplicate || !quotaGroup ? new QuotaGroup(this) : quotaGroup;

    question.quotaGroupRef = newQuotaGroup.ref;

    if (!quotaGroup || duplicate) {
      set.options.forEach((option: Option) => {
        let count = 0;
        if (duplicate) {
          const duplicateRef = duplicateOptionSetMap.get(option.ref);
          const quota = quotaGroup.quotas.find(quota => ((quota.rule as Rule).value as RuleChoiceValue)?.set1 === duplicateRef);
          count = quota?.count ?? 0;
        }
        this.addOptionQuotaToQuestionQuotaGroup(option, question, null, newQuotaGroup, count);
      })
    }

    this.updateQuestionQuotaGroupName(question, newQuotaGroup);

    if (!this.getQuotaByRef(newQuotaGroup.ref)) {
      this.addQuota(newQuotaGroup);
    }

    // ensure existing/duplicated quota group is valid
    question.validateQuestionQuotaGroup(this);

    return newQuotaGroup;
  }

  addOptionQuotaToQuestionQuotaGroup(option: Option, question: QuestionType1d, index = null, quotaGroup: QuotaGroup = null, count: number = null): Quota | null {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    if (quotaGroup) {
      const isPlaceholder = question.getSet1(this).getPlaceholderOption() === option;
      let quota = this.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option);
      if (!quota && !isPlaceholder) {
        const ruleValue = { set1: option.ref } as RuleChoiceValue;
        const rule = new Rule(this, null, question.ref, ruleValue, RuleOperator.EqualsAny, false);
        quota = new Quota(this, null, stripHTML(option.label), rule, count ?? 0);
        quotaGroup.addQuota(quota, this, index);
      }
      return quota;
    }
    return null;
  }

  updateOptionQuotaInQuestionQuotaGroup(option: Option, question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    const optionQuota = quotaGroup ? this.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option) : null;
    if (optionQuota) {
      optionQuota.name = stripHTML(option.label);
    }
  }

  updateQuestionQuotaGroupName(question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    if (quotaGroup) {
      const cleanName = question.getCleanName(false, true);
      quotaGroup.name = cleanName?.length ? cleanName : question.getCodeLabel();
    }
  }

  updateQuestionQuotaGroupQuotaPositions(question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    if (quotaGroup) {
      const options = this.getOptionSetByRef((question as QuestionType1d).set1).options;
      quotaGroup.quotas = options.map(option => this.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option)).filter(Boolean);
    }
  }

  deleteQuota(quota: Quota | QuotaGroup) {
    const index = this.getQuotaIndex(quota);
    if (index > -1) {
      this.quotas.splice(index, 1);
    }
  }

  deleteQuestionQuotaGroup(question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    if (quotaGroup) {
      (question as QuestionType1d).quotaGroupRef = null;
      this.deleteQuota(quotaGroup);
    }
  }

  deleteOptionQuotaInQuestionQuotaGroup(option: Option, question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    const optionQuota = quotaGroup ? this.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option) : null;
    if (optionQuota) {
      quotaGroup.deleteQuota(optionQuota);
    }
  }

  getQuotaByRef(ref: string): Quota | QuotaGroup | null {
    return this.quotas.find(el => el.ref === ref ?? null);
  }

  getQuotaIndex(quota: Quota|QuotaGroup): number {
    return this.getQuotaIndexByRef(quota.ref);
  }

  getQuotaIndexByRef(ref: string): number {
    return this.quotas.findIndex(el => el.ref === ref);
  }

  getQuotaGroupQuestion(quotaGroup: QuotaGroup): QuestionType1d | undefined {
    return this.getAllNodes(QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS).find((node: QuestionType1d) => {
      return node.quotaGroupRef === quotaGroup.ref
    }) as QuestionType1d;
  }

  getOptionQuotaInQuestionQuotaGroup(quotaGroup: QuotaGroup, option: Option): Quota {
    return quotaGroup.quotas.find(quota => ((quota.rule as Rule).value as RuleChoiceValue).set1 === option.ref);
  }

  getQuestionQuotaGroup(question: Node): QuotaGroup | null {
    if (!QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS.includes(question.type)) {
      return null;
    }

    return (question as QuestionType1d).getQuestionQuotaGroup(this);
  }

  isObjectQuotaGroup(quotaGroupObject: any): boolean {
    const objProps = Object.keys(quotaGroupObject);
    const classInstance = new QuotaGroup(new Survey());
    const classProperties = Object.keys(classInstance);

    return objProps.length === classProperties.length && objProps.every(prop => classProperties.includes(prop));
  }

  /*********************************************************************************************************************/
  /* TRANSLATIONS */
  /*********************************************************************************************************************/

  addLocales(locales: Array<Locale>) {
    const addedLocales = this.getAddedLocaleIds();
    locales.forEach(locale => {
      if (!addedLocales.includes(locale.ISO15897Code)) {
        this.addTranslations(this.getTranslationData(locale));
      }
    })
  }

  addActiveLocale(locale: string|Locale) {
    locale = (locale instanceof Locale ? locale : new Locale(locale)).ISO15897Code;
    if (!this.locales.includes(locale)) {
      this.locales.push(locale);
    }
  }

  removeActiveLocale(locale: string|Locale) {
    locale = (locale instanceof Locale ? locale : new Locale(locale)).ISO15897Code;
    this.locales = this.locales.filter(activeLocale => activeLocale !== locale);
  }

  toggleActiveLocale(locale: string|Locale, isActive: boolean) {
    if (isActive) {
      this.addActiveLocale(locale);
    } else {
      this.removeActiveLocale(locale);
    }
  }

  addTranslations(data: TranslationData) {
    data.array.forEach(translation => {
      const [, ref, _, translatedTxt] = translation,
        nodeOrOption: Node | Option = this.getNodeByRef(ref) || this.getOptionByRef(ref);

      nodeOrOption.addTranslation(data.targetLocale.ISO15897Code, translatedTxt);
    })
  }

  addTranslationsFromFile(file: TranslationFile) {
    this.addTranslations(file.data)
  }

  getTranslationData(targetLocale: Locale = Locale.default): TranslationData {
    //TODO: include page titles when exposed in UI
    const data = [];

    this.getAllNodes().forEach(node => {
      if (!node?.text?.length) {
        // Don't add empty nodes
        return;
      }

      data.push({
        'context': `Q${node.code}`,
        'id': node.ref,
        'original-text': node.text,
        'translated-text': (node?.i18n && node.i18n[targetLocale.ISO15897Code]) ?? null,
      });

      [QuestionType1d, QuestionType2d].forEach((nodeType, index) => {
        if (!(node instanceof nodeType)) {
          return;
        }

        const set = this.getOptionSetByRef(node[`set${index + 1}`]);
        set.options.forEach((option: Option) => {
          if (!option.label.length) {
            // Don't add empty options
            return;
          }

          const existingTranslation = data.find(translation => translation.id === option.ref);
          if (existingTranslation) {
            // Add this context to existing translation record
            existingTranslation.context += `, Q${node.code} - ${index ? 'Column' : 'Row'} ${option.code}`;
            return;
          }

          data.push({
            'context': `Q${node.code} - ${index ? 'Column' : 'Row'} ${option.code}`,
            'id': option.ref,
            'original-text': option.label,
            'translated-text': (option.i18n && option.i18n[targetLocale.ISO15897Code]) ?? null,
          })
        })
      })
    })

    return new TranslationData(data, targetLocale);
  }

  getAddedLocaleIds(): Array<string> {
    return this.getAllNodes().reduce((locales: string[], node) => {
      let setLocales = [];
      [QuestionType1d, QuestionType2d].forEach((nodeType, index) => {
        if (!(node instanceof nodeType)) {
          return;
        }

        const set = this.getOptionSetByRef(node[`set${index + 1}`]);
        set.options.forEach((option: Option) => {
          if (!option.i18n) {
            return;
          }

          setLocales = [...setLocales, ...Object.keys(option.i18n)]
        });
      });

      return [...locales, ...Object.keys(node.i18n), ...setLocales]
        .filter((value, index, array) => array.indexOf(value) === index);
    }, []);
  }

  getAddedLocales(): Array<Locale> {
    return this.getAddedLocaleIds().map(locale => new Locale(locale));
  }

  deleteTranslationsFor(locale: Locale) {
    if (this.locale === locale.ISO15897Code) {
      throw new CannotDeleteDefaultLocaleTranslationsError(locale);
    }

    this.getAllNodes().forEach(node => {
      [QuestionType1d, QuestionType2d].forEach((nodeType, index) => {
        if (!(node instanceof nodeType)) {
          return;
        }

        const set = this.getOptionSetByRef(node[`set${index + 1}`]);
        set.options.forEach((option: Option) => {
          delete option.i18n[locale.ISO15897Code];
        })
      });

      delete node.i18n[locale.ISO15897Code];
    })

    if (this.locales.includes(locale.ISO15897Code)) {
      // Remove locale from locales array
      this.locales.splice(this.locales.indexOf(locale.ISO15897Code), 1);
    }
  }

  /*********************************************************************************************************************/
  /* ERRORS */
  /*********************************************************************************************************************/

  addSurveyError(error: SurveyError) {
    this.errors.push(error);
  }

  resetSurveyErrors(errorItemRef: Ref = null, errorEditElementRef: Ref = null, errorProperties: SurveyErrorProperty[] = null, errorTypes: SurveyErrorType[] = null) {
    const resetAll = errorItemRef === null && errorEditElementRef === null && errorProperties === null && errorTypes === null;
    this.errors = resetAll
      ? []
      : this.filterSurveyErrors(errorProperties, errorItemRef, errorEditElementRef, errorTypes, true) as SurveyError[];
  }

  filterSurveyErrors(
    errorProperties: SurveyErrorProperty[] = null,
    errorItemRef: Ref = null,
    errorEditElementRef: Ref = null,
    errorTypes: SurveyErrorType[] = null,
    negate: boolean = false,
    messagesOnly: boolean = false,
    propertiesOnly: boolean = false
  ): SurveyError[] | string[] | SurveyErrorProperty[] {

    let errors: SurveyError[];

    if (errorProperties === null && errorItemRef === null && errorEditElementRef === null && errorTypes === null) {
      errors = this.errors;
    } else {
      errors = this.errors.filter(error => {
        const matchItemRef = errorItemRef === null || error.errorItemRef === errorItemRef;
        const matchEditElementRef = errorEditElementRef === null || error.errorEditElementRef === errorEditElementRef;
        const matchProperty = errorProperties === null || errorProperties.includes(error.errorProperty);
        const matchType = errorTypes === null || errorTypes.includes(error.errorType);

        if (negate) {
          // exclude if error doesn't match all conditions
          return !matchItemRef || !matchEditElementRef || !matchProperty || !matchType;
        }

        return matchItemRef && matchEditElementRef && matchProperty && matchType;
      });
    }

    return messagesOnly
      ? errors.map(error => error.message)
      : propertiesOnly
        ? errors.map(error => error.errorProperty).sort()
        : errors;
  }
}
