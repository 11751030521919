import {defineStore} from "pinia";
import i18n from "@/plugins/i18n";
import Survey from "@/models/Survey";
import Page from "@/models/Page";
import {ACTIVE_EDIT_ELEMENT_TYPES, ActiveEditElement, ActiveEditElementType, Ref} from "@/typings/global-types";
import Node, {NodeTypeId} from "@/models/Node";
import {createNode, nodeTypeClasses} from "@/utils/nodeTypeFactory";
import OptionSet from "@/models/OptionSet";
import QuestionType1d from "@/models/nodeTypes/QuestionType1d";
import RuleSet from "@/models/RuleSet";
import Element from "@/models/Element";
import {isDevEnv} from "@/constants/config";
import SurveyError, {SurveyErrorProperty, SurveyErrorType} from "@/models/errors/SurveyError";
import {cloneDeep} from "@/utils/lodash";
import {getTypedSurvey} from "@/tests/mocks/seeds/surveyStore";
import surveyMock from "@/tests/mocks/models/survey1";
import Block from "@/models/Block";
import BlockElement from "@/models/BlockElement";

const { t } = i18n.global;

export const getters = {
  hasSurvey(): boolean {
    return this.survey?.elements && this.survey?.elements.length > 0;
  },

  /*********************************************************************************************************************/
  /* ↓↓ SURVEY ELEMENT GETTERS ↓↓
  /*********************************************************************************************************************/

  getActiveEditElement(): ActiveEditElement {
    // returns active EDIT element, which includes nodes
    if (this.activeEditElementRef === null || this.activeEditElementType === null) {
      return null;
    }
    if (this.activeEditElementType === Node) {
      return this.survey.getNodeByRef(this.activeEditElementRef);
    }
    return this.survey.getElementByRef(this.activeEditElementRef);
  },
  getActiveElement(): Element {
    // returns page if active element is a node
    return this.getActiveQuestion
      ? this.survey.getPageOfNode(this.getActiveQuestion)
      : this.getActiveEditElement;
  },
  getFlattenedIndexOfActiveElement: (state: any) =>  (includeBlocksInFlattenedArray: boolean = false): number | null => {
    // provides a flattened index to determine the position of an active element in relation to another
    // (i.e. need to verify P1 is before P2 when they are in different blocks)
    // the flattened array includes only Elements (i.e. doesn't flatten nodes)
    return state.getActiveElement ? state.survey.getElementFlattenedIndex(state.getActiveElement, includeBlocksInFlattenedArray) : null;
  },

  // ----- BLOCKS -----

  getActiveBlock(): Block | null {
    return this.activeEditElementType === Block ? this.getActiveEditElement : null;
  },
  getBlockOfActiveElement(): Block | null {
    return this.getActiveEditElement instanceof Block
      ? null
      : this.survey.getParentBlockOfElement(this.getActiveEditElement);
  },
  getActiveBlockOrBlockOfActiveElement(): Block | null {
    return this.getActiveBlock ?? this.getBlockOfActiveElement;
  },
  getIndexOfActiveBlock(): number | null {
    return this.getActiveBlock ? this.survey.getBlockIndex(this.getActiveBlock) : null;
  },
  getIndexOfParentBlockOfActiveElement(): number | null {
    return this.getActiveEditElement instanceof Block ? null : this.survey.getParentBlockOfElement(this.getActiveEditElement);
  },

  // ----- BLOCK ELEMENTS: PAGES | RULE SETS -----

  getActiveBlockElement(): BlockElement | null {
    return this.getActivePage ?? this.getActiveRuleSet;
  },
  getIndexOfActiveBlockElement(): number | null {
    // returns SURVEY block element index within it's parent - if active edit element is node, it'll return it's page
    return this.getActiveQuestion ? this.getPageIndexOfActiveQuestion
      : this.getActiveBlockElement ? this.survey.getBlockElementIndex(this.getActiveEditElement, this.getActiveBlockOrBlockOfActiveElement)
      : null;
  },

  // ----- PAGES -----

  getActivePage(): Page | null {
    return this.activeEditElementType === Page ? this.getActiveEditElement : null;
  },
  getPageOfActiveQuestion(): Page | null {
    return this.getActiveQuestion
      ? this.survey.getPageOfNode(this.getActiveQuestion)
      : null;
  },
  getActivePageOrPageOfActiveQuestion(): Page | null {
    return this.getActivePage ?? this.getPageOfActiveQuestion;
  },
  getPageIndexOfActiveQuestion(): number | null {
    return this.getPageOfActiveQuestion
      ? this.survey.getBlockElementIndex(this.getPageOfActiveQuestion, this.getActiveBlockOrBlockOfActiveElement)
      : null;
  },

  // ----- NODES -----

  getActiveQuestion(): Node | null {
    return this.activeEditElementType === Node ? this.getActiveEditElement : null;
  },
  getIndexOfActiveQuestionOnPage(): number | null {
    return this.getActiveQuestion ? this.getPageOfActiveQuestion.getNodeIndex(this.getActiveQuestion) : null;
  },

  // ----- RULE SETS -----

  getActiveRuleSet(): RuleSet | null {
    return this.activeEditElementType === RuleSet ? this.getActiveEditElement : null;
  },

  // ----- OPTION SETS -----

  getActiveOptionSet: (state: any) => (setNum = 1) => {
    const question = state.getActiveQuestion;
    if (question instanceof QuestionType1d && (question['set' + setNum] ?? false)) {
      return state.survey.getOptionSetByRef(question['set' + setNum]);
    }
    return null;
  },
  getActiveOptionSet1(): OptionSet {
    return this.getActiveOptionSet(1);
  },
  getActiveOptionSet2(): OptionSet {
    return this.getActiveOptionSet(2);
  },

  /*********************************************************************************************************************/
  /* ↑↑ SURVEY ELEMENT GETTERS ↑↑
  /*********************************************************************************************************************/


  questionRoutes: (state: any) => (
    sliceBeforeFlattenedPageIndex: boolean | null = null,
    flattenedPageSliceIndex: number | null = null,
    types: NodeTypeId[] = null,
    isPipe = false
  ) => {
    // returns questions by type and page position, if provided
    const nodes = state.survey.getAllNodes(
      types,
      sliceBeforeFlattenedPageIndex ? flattenedPageSliceIndex : null,
      !sliceBeforeFlattenedPageIndex ? flattenedPageSliceIndex : null
    );

    return nodes.map(node=> {
      return {
        label: node.getCleanTitle(),
        code: node.getCodeLabel(isPipe),
        ref: node.ref,
        type: node.type,
        disabled: false,
      }
    });
  },
  pageRoutes: (state: any) => (sliceBeforeFlattenedPageIndex: boolean | null = null, flattenedPageSliceIndex: number | null = null) => {
    const pages = state.survey.getAllPages(
      sliceBeforeFlattenedPageIndex ? flattenedPageSliceIndex : null,
      !sliceBeforeFlattenedPageIndex ? flattenedPageSliceIndex : null
    );

    return pages.map(page => {
      const nodeLabels = page.getAllNodeLabels();

      return {
        label: `${page.getCleanTitle()}${nodeLabels ? ' (' + nodeLabels + ')' : ''}`,
        code: page.getCodeLabel(),
        goToRef: page.ref,
        disabled: false,
      }
    });
  },
  getRoutesWithValidOptionSet: (state: ReturnType<typeof useSurveyStore>) => (routes: any[], setNum = 1) => {
    return routes?.filter(route => {
      if (route.ref) {
        const node = state.survey.getNodeByRef(route.ref)
        const optionSet = state.survey.getOptionSetByRef(node['set' + setNum])
        const placeholderOption = optionSet?.getPlaceholderOption()
        const options = optionSet.options.filter(option => option.ref !== placeholderOption.ref )

        return options.length
      }
    })
  },
  isEdited(): boolean {
    return this.edited;
  },
  isSessionStarted(): boolean {
    return this.sessionStarted;
  },
  isSessionLocked(): boolean {
    return this.sessionLocked;
  },
  isSessionSaved(): boolean {
    return this.sessionSaved;
  },
  surveyTitle(): string {
    return this?.entity?.name || t('builder.draftSurvey.defaultName')
  },
  editElementHasErrors: (state: ReturnType<typeof useSurveyStore>) => (element: ActiveEditElement = null): boolean => {
    return state.getEditElementErrors(element).length > 0
  },
  getEditElementErrors: (state: ReturnType<typeof useSurveyStore>) => (element: ActiveEditElement): SurveyError[] => {
    return state.getErrors(null, null, element.ref);
  },
  hasErrors: (state: ReturnType<typeof useSurveyStore>) => (errorProperties: SurveyErrorProperty[] = null, errorItemRef: Ref = null, errorEditElementRef: Ref = null, errorTypes: SurveyErrorType[] = null): boolean => {
    return !!state.getErrors(errorProperties, errorItemRef, errorEditElementRef, errorTypes).length;
  },
  getErrors: (state: ReturnType<typeof useSurveyStore>) => (errorProperties: SurveyErrorProperty[] = null, errorItemRef: Ref = null, errorEditElementRef: Ref = null, errorTypes: SurveyErrorType[] = null): SurveyError[] => {
    return state.survey.filterSurveyErrors(errorProperties, errorItemRef, errorEditElementRef, errorTypes);
  },
  getErrorMessages: (state: ReturnType<typeof useSurveyStore>) => (errorProperties: SurveyErrorProperty[] = null, errorItemRef: Ref = null, errorEditElementRef: Ref = null, errorTypes: SurveyErrorType[] = null): string[] => {
    return state.survey.filterSurveyErrors(errorProperties, errorItemRef, errorEditElementRef, errorTypes, false, true);
  },
  isAddingElementsDisabled: (state: ReturnType<typeof useSurveyStore>): boolean => {
    return state.isPublished || state.isSessionLocked
  }
};
export const actions = {
  setSurvey(survey: Survey) {
    this.survey = survey;
    //for testing purposes, uncomment below to see state changes in console
    // this.subscribe();
    const firstElement = this.survey.elements[0];
    let firstNode = null;
    if (firstElement instanceof Block || firstElement instanceof Page) {
      firstNode = this.survey.getAllPages()?.[0]?.nodes?.[0] ?? null;
    }
    this.setActiveEditElement(firstNode ?? firstElement);
  },
  initSurvey() {
    //for testing purposes, uncomment below to set to test survey
    // if (isDevEnv()) {
      // this.setSurvey(getTypedSurvey())
      // this.setSurvey(getTypedSurvey(surveyMock.blockedSurvey))
      // this.setSurvey(getMaskedQuestionSurvey())
    // }
    this.addNewPage(NodeTypeId.SingleSelect, null, true, 1, 1)
  },
  clearSurvey() {
    this.survey = null;
  },
  subscribe() {
    this.unsubscribe();
    this.unsubscribeHandler = this.$subscribe((mutation, state) => {
      if (isDevEnv()) {
        console.info('MUTATION', mutation);
        console.info('MUTATED STATE', {...state});
        console.info('MUTATED SURVEY STATE', {...state?.survey})
      }
    });
  },
  unsubscribe() {
    if (this.unsubscribeHandler) {
      this.unsubscribeHandler();
    }
  },
  addNewBlock(
    addNodeType: NodeTypeId = null,
    blockIndex: number = null,
    setActive: boolean = true,
  ): Block {

    const block = new Block({survey: this.survey});
    const activeBlock = this.getActiveBlockOrBlockOfActiveElement;
    blockIndex = blockIndex ?? (activeBlock !== null ? this.survey.getBlockElementIndex(activeBlock) + 1 : null);

    this.survey.addBlock(block, blockIndex, true, false);

    let node = null;
    let page = null;

    if (addNodeType) {
      page = new Page({survey: this.survey});
      const defaultOrdinalities = cloneDeep(nodeTypeClasses[addNodeType]['DEFAULT_ORDINALITIES']);
      node = createNode({type: addNodeType, survey: this.survey}, defaultOrdinalities);
      page.addNode(node);

      this.survey.addPage(page, block, null, false);
    }

    if (setActive) {
      this.setActiveEditElement(block);
    }

    // position change validation is handled here, excluding the active block and children
    // to ensure the user doesn't immediately see validation errors due to empty values
    this.survey.handleElementPositionChange(
      [],
      page && setActive ? [page] : [],
      node && setActive ? [node] : [],
      setActive ? [block] : []
    );

    return block;
  },
  addNewPage(
    addNodeType: NodeTypeId = null,
    pageIndex: number = null,
    setActive: boolean = true,
    pageCode: number = null,
    nodeCode: number = null,
    block: Block = null,
  ): Page {

    const page = new Page({survey: this.survey, code: pageCode});

    if (this.survey.requireBlocks) {
      block = block ?? this.getActiveBlockOrBlockOfActiveElement;

      if (!block) {
        // this could happen for a new survey that doesn't yet have any elements,
        // add a new block to add the page to
        block = this.survey.addBlock(new Block({survey: this.survey}), null);
      }
    } else {
      block = null;
    }

    pageIndex = pageIndex ?? (this.getIndexOfActiveBlockElement !== null ? this.getIndexOfActiveBlockElement + 1 : null);

    let node = null;

    if (addNodeType) {
      const defaultOrdinalities = cloneDeep(nodeTypeClasses[addNodeType]['DEFAULT_ORDINALITIES']);
      node = createNode({type: addNodeType, survey: this.survey, code: nodeCode}, defaultOrdinalities);
      page.addNode(node);
    }

    this.survey.addPage(page, block, pageIndex, false);

    if (setActive) {
      this.setActiveEditElement(node ?? page ?? block);
    }

    // position change validation is handled here, excluding the new active page and question
    // to ensure the user doesn't immediately see validation errors due to empty values
    this.survey.handleElementPositionChange([], setActive ? [page] : [], node && setActive ? [page.nodes[0]] : []);

    return page;
  },
  addNewNode(nodeType: NodeTypeId, setActive: boolean = true): Node | null {
    let page = this.getActivePageOrPageOfActiveQuestion;

    if (!page && this.getActiveBlockOrBlockOfActiveElement) {
      page = new Page({survey: this.survey});
      this.getActiveBlockOrBlockOfActiveElement.addPage(this.survey, page);
    }

    if (page instanceof Page) {
      const defaultOrdinalities = cloneDeep(nodeTypeClasses[nodeType]['DEFAULT_ORDINALITIES']);
      const node = createNode({type: nodeType, survey: this.survey}, defaultOrdinalities);
      const nodeIndex = this.getIndexOfActiveQuestionOnPage !== null ? this.getIndexOfActiveQuestionOnPage + 1 : null;

      page.addNode(node, nodeIndex);

      if (setActive) {
        this.setActiveEditElement(node);
      }

      // position change validation is handled here, excluding the new active question
      // to ensure the user doesn't immediately see validation errors due to empty values
      this.survey.handleElementPositionChange([], [], setActive ? [node] : []);

      return node;
    }

    return null;
  },
  addNewRuleSet(index: number = null, ruleSet: RuleSet = null, setActive: boolean = true, block: Block = null): RuleSet {
    block = block ?? this.getActiveBlockOrBlockOfActiveElement;
    index = index ?? (this.getIndexOfActiveBlockElement !== null ? this.getIndexOfActiveBlockElement + 1 : null);
    ruleSet = this.survey.addRuleSet(ruleSet ?? new RuleSet({survey: this.survey}), block, index, false);

    if (setActive) {
      this.setActiveEditElement(ruleSet);
    }

    // position change validation is handled here, excluding the new ruleset
    // to ensure the user doesn't immediately see validation errors due to empty values
    this.survey.handleElementPositionChange(setActive ? [ruleSet] : [], [], []);

    return ruleSet;
  },
  setActiveEditElement(element: ActiveEditElement = null) {
    this.activeEditElementRef = element?.ref ?? null;
    this.activeEditElementType = null;

    for (const type of ACTIVE_EDIT_ELEMENT_TYPES) {
      if (element instanceof type) {
        this.activeEditElementType = type;
        break;
      }
    }
  },
  setActiveEditElementToNearest(
    previousElement: boolean = true,
    oldActiveEditElementIndex: number,
    oldElementParent: Element = null,
    elementType: ActiveEditElementType
  ) {
    let newActiveElement: ActiveEditElement = null;

    const getNextValidElement = (elements: ActiveEditElement[], oldIndex: number, elementType: ActiveEditElementType = null): ActiveEditElement | null => {
      const isValidElement = (element: ActiveEditElement) => {
        return element && (!elementType || element instanceof elementType);
      };

      if (previousElement && oldIndex === 0 && isValidElement(elements[0])) {
        return elements[0];
      }

      const searchByDirection = (direction) => {
        for (let i = oldIndex + direction; i >= 0 && i < elements.length; i += direction) {
          if (isValidElement(elements[i])) {
            return elements[i];
          }
        }
        return null;
      }

      const direction = previousElement ? -1 : 1;

      // reverse direction if none found
      return searchByDirection(direction) ?? searchByDirection(-direction);
    };

    switch (elementType) {
      case Node:
        // set to nearest question, if any, else page
        newActiveElement = getNextValidElement((oldElementParent as Page).nodes, oldActiveEditElementIndex) || oldElementParent;
        break;

      case Page:
      case RuleSet:
        // if page, set to nearest page element, if any, else if blocks required, set to next block
        // rule sets appear to be attached to it's preceding page, so the next active element will be it's preceding page
        newActiveElement = getNextValidElement((oldElementParent as Block)?.elements ?? this.survey.elements, oldActiveEditElementIndex, Page) || oldElementParent;
        break;

      case Block:
        // set to nearest block
        newActiveElement = getNextValidElement(this.survey.elements, oldActiveEditElementIndex);
        break;
    }

    if (newActiveElement instanceof Page && newActiveElement.nodes.length) {
      // set first node of page to active
      newActiveElement = newActiveElement.nodes[0];
    }

    // should always at least be one page/question/block
    this.setActiveEditElement(newActiveElement ?? this.survey.elements[0] ?? null);
  },
  deleteElement(element: ActiveEditElement = null) {
    let activeEditElementIndex: number = null;
    let elementParent: Element = null;

    element = element ?? this.getActiveEditElement;

    let elementType = null;
    for (const type of ACTIVE_EDIT_ELEMENT_TYPES) {
      if (element instanceof type) {
        elementType = type;
        break;
      }
    }

    if (element instanceof Element) {
      if (element instanceof Block) {
        activeEditElementIndex = this.survey.getBlockIndex(element);
      } else if (element instanceof BlockElement) {
        const block = this.survey.getParentBlockOfElement(element);
        activeEditElementIndex = this.survey.getBlockElementIndex(element, block);
        elementParent = block;
      }
      this.survey.deleteElement(element);
    } else if (element instanceof Node) {
      const node = element as Node;
      const page = this.survey.getPageOfNode(node);

      activeEditElementIndex = page.getNodeIndex(node);
      elementParent = page;

      page.deleteNode(node, this.survey);
    }

    // add a new page with single select node, if none exist (if blocks required, a block will be added)
    if (!this.survey.elements.length) {
      this.addNewPage(NodeTypeId.SingleSelect, null, true, 1, 1);
    }

    this.setActiveEditElementToNearest(true, activeEditElementIndex, elementParent, elementType);
  },
  duplicateElement(newName: string = null, element: ActiveEditElement = null) {
    element = element ?? this.getActiveEditElement;

    let newElement: ActiveEditElement = null;
    let elementIndex: number = null;

    if (element instanceof Element) {
      const block = this.survey.getParentBlockOfElement(element);
      elementIndex = this.survey.getBlockElementIndex(element, block);
      newElement = this.survey.duplicateElement(element, elementIndex + 1, newName); // note, if page, any linked logics will also be duplicated

    } else if (element instanceof Node) {
      const node = element as Node;
      const page = this.survey.getPageOfNode(node);
      elementIndex = page.getNodeIndex(node);
      newElement = page.duplicateNode(element as Node, this.survey, newName, elementIndex + 1, true);
    }

    if (newElement instanceof Page && newElement.nodes.length) {
      // set first node of page to active
      newElement = newElement.nodes[0];
    } else if (newElement instanceof RuleSet) {
      // rule sets are attached to the preceding page, make that page active (no need to open duplicate ruleset modal)
      newElement = newElement.getLinkedPage(this.survey);
    }

    this.setActiveEditElement(newElement);
  },
  markEdited() {
    this.edited = true
    this.isNew = false
  },
  resetEdited() {
    this.edited = false
  },
  saveSession() {
    this.sessionSaved = true
  },
  resetSession() {
    this.sessionSaved = false
  },
  startSession() {
    this.sessionStarted = true
  },
  finishSession() {
    this.sessionStarted = false
  },
  lockSession(editingUserId) {
    this.editingUserId = editingUserId;
    this.sessionLocked = true;
    this.finishSession();
  },
  setNew(val: boolean) {
    this.isNew = val
  },
  setPublished(val: boolean) {
    this.isPublished = val
  },
  setEntity(entity: Record<string, any>) {
    this.entity = entity;
  },
};

export const useSurveyStore = defineStore('surveyStore', {
  state: () => ({
    survey: null as Survey,
    activeEditElementRef: null as string,
    activeEditElementType: Page as unknown as ActiveEditElementType,
    unsubscribeHandler: null,
    edited: false,
    sessionStarted: false,
    sessionSaved: false,
    sessionLocked: false,
    editingUserId: null as string,
    isNew: true,
    isPublished: false,
    entity: null as Record<string, any>,
    v2: true, // enable the features of the second version: Re-use Survey, Import Questions Library
  }),
  getters,
  actions
})